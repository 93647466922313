import React, { useRef, useState, useEffect } from 'react';
import Cookies from "js-cookie";
import { useSelector, useDispatch } from 'react-redux';
import MainProfile from "../section/MainProfile";
import SpeakToMe from "../section/SpeakToMe";
import AskMe from "../section/AskMe";
import ExpertAssist from '../section/ExpertAssist';
import SolutionTypes from '../section/SolutionType';
import WhyImportant from '../section/WhyImportant';
import VideoSwiper from '../carousel/VideoSwiper';
import BestSelling from '../section/BestSelling';
import Carousel from '../carousel/Carousel';
import Stories from '../section/Stories';
import RecruitmentProfile from '../section/RecruitmentProfile';
import DelayForm from '../common/DelayForm';
import CarouselSecondary from '../carousel/CarouselSecondary';
import useMicroSiteEncrypt from '../CustomHook/useMicroSiteEncrypt';
import { eventTracking } from '../../redux/actions/micrositeAction';
import RecaptchaComponent from '../common/RecaptchaComponent';
import { IdleTimerProvider } from 'react-idle-timer';
import CarouselTopical from '../carousel/CarouselTopical';

const MainContainer = (props) => {
    const dispatch = useDispatch();
    const idleTimerRef = useRef(null);
    const intervalRef = useRef(null);
    const [speakTitle, setSpeakTitle] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [askTitle, setAskTitle] = useState("");
    const [askButtonTitle, setAskButtonTitle] = useState("");
    const [speakSubTitle, setSpeakSubTitle] = useState("");
    const [speakButtonTitle, setSpeakButtonTitle] = useState("");
    const [timeoutId, setTimeoutId] = useState(null); // State to track timeout ID
    const [hasCalledApi, setHasCalledApi] = useState(false); // State to track if API call has been made
    const profile_type = useSelector((state) => state.microsite?.profile_data?.profile_type);
    const home_data = useSelector((state) => state.microsite?.home_data);
    const current_flow = useSelector((state) => state.microsite?.home_data?.flow);
    const profile_data = useSelector((state) => state.microsite?.profile_data);
    const solution_data = useSelector((state) => state.microsite?.solution_data);
    const { microSiteEncrypt, token, error } = useMicroSiteEncrypt();
    const [open, setOpen] = useState(false);
    const captcha_key =
    window.location.origin.includes("spdevmfp") ||
    window.location.origin.includes("localhost")
      ? "6LfunNgbAAAAAEM0X3hcwSBYKJwIqHmmInfz0uFK"
      : window.location.origin.includes("sppreprod") ||
        window.location.origin.includes("sptestmfp")
      ? "6Ldeg6YaAAAAAAZqU7AQ_j-lG4LcnwP4jfNFiCJJ"
      : "6Lf9fqoaAAAAAH8JhnTg9gLI3KsYKmR2tdCRVg8i";

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        handleSpeakSection();
        
        // Only start the event tracking if we have current_flow
        if (current_flow) {
            const startTimeout = setTimeout(() => {
                generateEventAction("start");
            }, 2000);
            
            intervalRef.current = setInterval(() => {
                generateEventAction("call");
            }, 20000);

            const openTimeout = setTimeout(() => {
                setOpen(true);
            }, 8000);

            // Cleanup function
            return () => {
                clearInterval(intervalRef.current);
                clearTimeout(startTimeout);
                clearTimeout(openTimeout);
                generateEventAction("end");
            }
        }
    }, [current_flow]); // Add current_flow as dependency
    
    const getRecaptchaToken = async () => {
        return new Promise((resolve, reject) => {
        // Load the reCAPTCHA script
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${captcha_key}`;
        script.async = true;
        script.onload = () => {
            window.grecaptcha.ready(() => {
            window.grecaptcha.execute(captcha_key, { action: 'submit' })
                .then(token => {
                resolve(token);
                })
                .catch(err => {
                reject(err);
                });
            });
        };
        document.body.appendChild(script);
        });
    };
    
    const generateEventAction = async (action) => {
        // Add guard clause to prevent API call without current_flow
        if (!current_flow) {
            console.log("Skipping event tracking - current_flow not available");
            return;
        }

        let tokenEvent = "";
        let url = window?.location?.href?.replace("/site", "");
        url = url.replace("/profile", "/");
        let flow = current_flow;
        let contact_id = "";
        console.log("Cookies", Cookies.get("new_c"));
        if (Cookies.get("new_c")) {
            contact_id = Cookies.get("new_c");
        } else if (Cookies.get("c")) {
            contact_id = Cookies.get("c");
        }
        let recaptcha = await getRecaptchaToken();
        let end_time = new Date();
        tokenEvent = await microSiteEncrypt();
        if (!Cookies.get("vtkn")) {
            Cookies.set("vtkn", tokenEvent);
        }
        if (tokenEvent && tokenEvent !== "") {
            dispatch(eventTracking(tokenEvent, recaptcha, url, action, (action === "call" || action === "end" || action === "idle") ? formatDateToISD(end_time) : "", flow, contact_id));
        }
    };
    
    function formatDateToISD(dateString) {
        const date = new Date(dateString);

        // Helper function to add leading zero
        const padZero = (num) => (num < 10 ? `0${num}` : num);

        const year = date.getFullYear();
        const month = padZero(date.getMonth() + 1); // Months are zero-indexed
        const day = padZero(date.getDate());
        const hours = padZero(date.getHours());
        const minutes = padZero(date.getMinutes());
        const seconds = padZero(date.getSeconds());

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const handleSpeakSection = () => {
        if (current_flow === "product") {
            setSpeakTitle("Insurance can be complex to understand!");
            setSpeakSubTitle("In fact, too much information can be overwhelming and confusing.");
            setSpeakButtonTitle("Speak to me");
            setAskTitle("Any questions to clarify?");
            setAskButtonTitle("Ask me");
        } else if (current_flow === "category") {
            setSpeakTitle("Not sure which one will be good for you?");
            setSpeakSubTitle("Too much information can be confusing. Would you like expert advice?");
            setSpeakButtonTitle("Recommend a plan");
            setAskTitle("Any questions to clarify?");
            setAskButtonTitle("Ask me");
        } else if (current_flow === "leader") {
            setSpeakTitle("Not sure if this works for you?");
            setSpeakSubTitle("Too much information can be confusing.");
            setSpeakButtonTitle("Speak to me");
            setAskTitle("Interested to join TATA AIA?");
            setAskButtonTitle("Tell me more");
        } else {
            setSpeakTitle("Got an idea but need some clarity?");
            setSpeakSubTitle("Can have a quick discussion for your requirement!");
            setSpeakButtonTitle("Let's discuss");
            setAskTitle("Any questions to clarify?");
            setAskButtonTitle("Ask me");
        }
    }

    const handleOnIdle = () => {
        if (!hasCalledApi) {
            console.log("User is idle. Setting up timeout for API call.");

            // Set a timeout to trigger the API call after 20 seconds
            const id = setTimeout(() => {
                console.log("Making API call after 20 seconds of being idle.");
                generateEventAction("idle");
                
                clearInterval(intervalRef.current);
                
                setHasCalledApi(true); // Mark that API call has been made
            }, 10*60*1000);

            setTimeoutId(id);
        }
    };

    const handleOnActive = () => {
        console.log("User is active.");

        // Reload the page if the API call was already made once
        if (hasCalledApi) {
            console.log("Reloading page since API call was already made once.");
            window.location.reload();
        }

        // Clear the timeout if the user becomes active
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    };

    return (
        <IdleTimerProvider
            ref={idleTimerRef}
            timeout={1000} // 1 minute timeout
            onIdle={handleOnIdle}
            onActive={handleOnActive}
        >
            <div>
                {/* <RecaptchaComponent onVerify={setRecaptchaToken} path={"Home"} /> */}
                <div className='bg-[#fff] pt-[6rem]'>
                    <div>
                        {profile_type !== "leader" ? <MainProfile profileData={profile_data} /> : <RecruitmentProfile profileData={profile_data} />}
                    </div>

                    {/* <TinderCarousel /> */}
                    {((current_flow === "product" || current_flow === "category" || current_flow === "leader") && home_data?.pdf_section?.length > 0) && <div className='lg:max-w-7xl py-8 px-32 max-sm:p-4 mx-auto bg-transparent'>
                        <Carousel
                            cardData={home_data?.pdf_section} internalData={home_data?.pdf_details}
                        />
                    </div>}
                    
                    {(current_flow === "greeting" && home_data?.image?.length > 0) && <div className='lg:max-w-7xl py-8 px-32 max-sm:p-4 mx-auto bg-transparent'>
                        <CarouselTopical cardData={home_data?.image}/>
                    </div>}
                    
                    {current_flow === "greeting" && <AskMe title={askTitle} buttonTxt={askButtonTitle} />}
                </div>

                {(current_flow === "product" || current_flow === "leader") && <div className='lg:max-w-5xl max-sm:w-full mx-auto'>
                    <WhyImportant currentFlow={current_flow} initialData={(current_flow === "product") ? home_data?.why : home_data?.what} />
                </div>}

                {(current_flow === "category" && home_data?.video?.length > 0) && <div className=''>
                    <div className='w-[80%] max-sm:w-[90%] mx-auto py-8'>
                        <p className='text-[#222] text-[36px] font-[TATAAIARegularPrint] max-sm:text-2xl not-italic font-semibold leading-[35px]'>Factors to consider</p>
                        <p className='text-[#222] text-[36px] font-[TATAAIARegularPrint] max-sm:text-2xl not-italic font-semibold leading-[35px]'>When choosing a plan</p>
                        <VideoSwiper videos={home_data?.video} />
                    </div>
                </div>}

                {(current_flow === "category") && <SpeakToMe title={speakTitle} subTitle={speakSubTitle} buttonTxt={speakButtonTitle} />}

                {(current_flow === "product" && home_data?.image?.length > 0) && <div className='bg-[#fff]'>
                    <div className='lg:max-w-7xl py-8 max-sm:p-4 max-sm:pb-12 px-32 mx-auto'>
                        {current_flow === "product" && <div className='text-[36px] leading-[39.6px]'>
                            <p className='text-[#222] text-[36px] max-sm:text-2xl font-[TATAAIARegularPrint] not-italic font-semibold'>Let's see how</p>
                            <p className='text-[#222] text-[36px] max-sm:text-2xl font-[TATAAIARegularPrint] not-italic font-bold'>{(home_data?.flow_description && home_data?.flow_description != "") ? home_data?.flow_description : "David secures his family."}</p>
                        </div>}
                        <CarouselSecondary
                            cardData={home_data?.image}
                            currentFlow={current_flow}
                        />
                    </div>
                    {current_flow === "greeting" && <SpeakToMe title={speakTitle} subTitle={speakSubTitle} buttonTxt={speakButtonTitle} />}
                </div>
                }

                {(current_flow === "leader" && Object.keys(home_data?.story)?.length > 0) && <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                    <p className='text-[#000] text-3xl font-[TATAAIARegularPrint] max-sm:text-2xl not-italic font-bold leading-[normal]'>Incredible success stories from <span className='text-[#000] font-extrabold'>TATA AIA</span></p>
                    <Stories ourStory={home_data?.story} />
                </div>}

                {((current_flow === "category" || current_flow === "greeting") && home_data?.onepager?.length > 0) && <div className='bg-white'>
                    <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                        <BestSelling bestSelling={home_data?.onepager} />
                    </div>
                </div>}

                {(current_flow !== "leader" && solution_data?.length > 0) && <div>
                    <div className='lg:max-w-7xl max-sm:p-4 max-sm:pb-0 px-32 mx-auto'>
                        <SolutionTypes solutionData={solution_data} />
                    </div>
                </div>}

                <div className='w-[80%] max-sm:w-[90%] mx-auto'>
                    <ExpertAssist />
                </div>
                <DelayForm open={open} handleClose={handleClose} />
            </div>
        </IdleTimerProvider>
    );
}

export default MainContainer;
