import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitLeadForm } from "../../redux/actions/micrositeAction";
import useMicroSiteEncrypt from "../CustomHook/useMicroSiteEncrypt";
import RecaptchaComponent from "../common/RecaptchaComponent";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import "../common/common.css";
import { ExclamationCircleFill } from "react-bootstrap-icons";

const CardCustom = (props) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [countryCode, setCountryCode] = useState('');
    const [mobileError, setMobileError] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const { microSiteEncrypt, token, error } = useMicroSiteEncrypt();
    const url_data = useSelector((state) => state.microsite?.url_data);
    const isLoading = useSelector((state) => state.microsite?.isLoading);
    const current_flow = useSelector((state) => state.microsite?.home_data?.flow);
    const customize_fields = useSelector((state) => state.microsite?.profile_data?.customization);
    const [recaptchaKey, setRecaptchaKey] = useState(300);
    
    const submitForm = () => {
        if (name == "" && customize_fields?.top_contact?.name?.validation == "required") {
            setNameError(true);
            return;
        } else if ((mobile == "" || mobileError == true) && customize_fields?.top_contact?.phone?.validation == "required") {
            setMobileError(true);
            return;
        } else if (emailError) {
            setEmailError(true);
            return;
        } else {
            setNameError(false);
            setMobileError(false);
        }
        
        let payload = {
            fullname: name,
            phoneNo: countryCode+"-"+mobile?.replace(countryCode,""),
            emailId: email,
            recaptcha_token : recaptchaToken,
            url: url_data
        }
        
        const generateToken = async () => {
            const token = await microSiteEncrypt(payload);
            if (token && token !== "") {
                dispatch(submitLeadForm(token ,payload, "Yes I Do", current_flow, onSuccess))
            }
        };
        
        generateToken();
    }

    const handleCountryChange = (value) => {
        if (value) {
        try {
            const phoneNumberObj = parsePhoneNumber(value);
            const countryCode = '+' + phoneNumberObj?.countryCallingCode;
            setCountryCode(countryCode);
        } catch (error) {
            console.error('Invalid phone number', error);
        }
        } else {
        setCountryCode('');
        }
    };    
    
    const onSuccess = () => {
        setName("");
        setMobile("");
        refreshRecaptcha();
    }
    
    const refreshRecaptcha = () => {
        setRecaptchaKey(prev => prev + 1);
    };
    
  return (
    <>
      <div className="max-w-sm text-left relative lg:w-[240px] xl:w-[280px] max-sm:w-[218px] mx-auto shadow-none rounded-[25px] bg-[#E1DFE0]">
        <div className="w-full border-black rounded-[26px] border-2 lg:h-[22rem] xl:h-[26rem] max-sm:h-[21rem] my-12 max-sm:my-28 p-4 py-2 space-y-1.5 max-sm:space-y-2 bg-cover bg-center">
            <p className="text-lg text-[#6B3F37] max-sm:text-md xl:text-2xl px-3 font-[TATAAIARegularPrint] leading-6 max-sm:leading-5 font-semibold">You've come to the right place!</p>
            <div className="bg-[#fff] rounded-[25px] p-4 py-1.5 max-sm:py-3 pb-1">
                <p className="text-[#d35c78] max-sm:text-sm font-[TATAAIAPrint] lg:text-[16px] xl:text-[17px] max-sm:leading-4 leading-5 font-semibold">For more information, reach out to me.</p>
                <div className='w-full lg:my-2 xl:my-1 mb-0 max-sm:my-1 max-sm:space-y-2.5 lg:space-y-2 xl:space-y-3'>
                    <div>
                        <label htmlFor="card-name" className="block text-[11px] max-sm:text-[10px] font-[HelveticaNeueLTStd] font-semibold text-[#444]">
                         Your name <span className="text-[#D62049]">{(customize_fields?.top_contact?.name?.validation == "required" ? " *":"")}</span>
                        </label>
                        <div className="mt-1 max-sm:mt-0 relative">
                            <input
                                type="text"
                                name="card-name"
                                id="card-name"
                                // placeholder={`Your name ${customize_fields?.top_contact?.name?.validation == "required" ? "*":""}`}      
                                className="block w-full h-8 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#0073BB] text-[11px] sm:leading-6"
                                value={name}
                                onChange={(e) => {
                                    const regex = /^[a-zA-Z ]*$/;
                                    if (regex.test(e.target.value)) {
                                        setName(e.target.value);
                                    }  
                                    setNameError(false);
                                }}
                            />
                            {nameError != false && <ExclamationCircleFill title="Enter a name" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                        </div>
                    </div>
                    <div>
                        <label className="block text-[11px] font-[HelveticaNeueLTStd] max-sm:text-[10px] font-semibold text-[#444]">
                            Mobile number <span className="text-[#D62049]">{(customize_fields?.top_contact?.phone?.validation == "required" ? " *":"")}</span>
                        </label>
                        <div className="mt-1 max-sm:mt-0 relative country-customize font-card-custom">
                            <PhoneInput
                                flags={flags}
                                value={mobile}
                                defaultCountry="IN"
                                limitMaxLength
                                countryCallingCodeEditable={false}
                                international={true}
                                onChange={(e) => {  
                                    handleCountryChange(e);
                                    if (e) {
                                    setMobile(e)
                                        if (isValidPhoneNumber(e)) {
                                            setMobileError(false); 
                                        } else {
                                            setMobileError(true); 
                                        }
                                    } else {
                                    setMobile("");
                                    setMobileError(false); 
                                    }
                                }}
                                // placeholder={`Mobile Number ${customize_fields?.top_contact?.phone?.validation == "required" ? "*":""}`}     
                            />
                            {mobileError != false && <ExclamationCircleFill title="Enter a valid mobile number" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}          
                        </div>
                    </div> 
                    <div>
                        <label htmlFor="card-name" className="block text-[11px] max-sm:text-[10px] font-[HelveticaNeueLTStd] font-semibold text-[#444]">
                         Your email
                        </label>
                        <div className="mt-1 max-sm:mt-0 relative">
                            <input
                                type="email"
                                name="card-email"
                                id="card-email"
                                // placeholder="Your email"      
                                className="block w-full h-8 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#0073BB] text-[11px] sm:leading-6"
                                value={email}
                                onChange={(e) => {
                                const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;                       
                                if (regex.test(e.target.value) || e.target.value == "") {
                                    setEmailError(false);
                                    } else {
                                    setEmailError(true);
                                    }   
                                    setEmail(e.target.value)
                                }}
                            />
                            {emailError != false && <ExclamationCircleFill title="Enter a valid email" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                        </div>
                    </div>      
                    <RecaptchaComponent 
                        key={recaptchaKey} 
                        onVerify={setRecaptchaToken} 
                      />
                        <button
                            type="button"
                            className={`flex bg-[#D62049] justify-center mx-auto items-center gap-2.5 shrink-0 border px-3 pb-0.5 pt-1 rounded-[30px] ${isLoading != false ? "w-28 h-[28px]":"w-fit"}`} disabled={isLoading}
                            onClick={(event) => {
                                event.stopPropagation();
                                submitForm();
                            }}
                        >
                        {isLoading != false ? <svg aria-hidden="true" className="w-4 h-4 text-[#D62049] animate-spin dark:text-gray-600 fill-[#fff]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        : <span className="text-[#fff] font-[TATAAIARegularPrint] my-auto max-sm:text-[10px] text-sm not-italic leading-2">Get a call back</span>}
                    </button>      
                </div>
            </div>      
        </div>
      </div>    
    </>  
  );
};

export default CardCustom;