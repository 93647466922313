import React, { useEffect, useState } from "react";
import "../section/section.css";
import {
  Label,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ExclamationCircleFill, X } from "react-bootstrap-icons";
import { CheckIcon, ChevronDownIcon, XCircleIcon } from "@heroicons/react/16/solid";
import { useDispatch, useSelector } from "react-redux";
import { submitLeadForm } from "../../redux/actions/micrositeAction";
import RecaptchaComponent from "./RecaptchaComponent";
import useMicroSiteEncrypt from "../CustomHook/useMicroSiteEncrypt";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import "./common.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LeadForm(props) {
  
  const dispatch = useDispatch();
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { microSiteEncrypt, token, error } = useMicroSiteEncrypt();
  const [selected, setSelected] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [age, setAge] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [countryCode, setCountryCode] = useState('');
  const [message, setMessage] = useState("");
  const [checkFlag, setCheckFlag] = useState(true);
  const url_data = useSelector((state) => state.microsite?.url_data);
  const isLoading = useSelector((state) => state.microsite?.isLoading);
  const current_flow = useSelector((state) => state.microsite?.home_data?.flow);
  const qualification_list = useSelector((state) => state.microsite?.qualification_list);
  const customize_fields = useSelector((state) => state.microsite?.profile_data?.customization);
  const [recaptchaKey, setRecaptchaKey] = useState(0);
  
  const submitForm = () => {    
    if (name == "" && customize_fields?.top_contact?.name?.validation == "required") {
      setNameError(true);
      return;
    } else if ((mobile == "" || mobileError == true) && customize_fields?.top_contact?.phone?.validation == "required") {
      setMobileError(true);
      return;
    } else if ((email == "" && customize_fields?.top_contact?.email?.validation == "required") || emailError == true ) {
      setEmailError(true);
      return;
    } else if (pincodeError == true) {
      setPincodeError(true);
      return;
    } else if (ageError == true) {
      setAgeError(true);
      return;
    } else {
      setNameError(false);
      setMobileError(false);
      setPincodeError(false);
      setEmailError(false);
      setAgeError(false);
    }
    let payload = {
      fullname: name,
      phoneNo: countryCode+"-"+mobile?.replace(countryCode,""),
      emailId: email,
      message: message,
      pincode: pincode,
      age: age,
      qualification: selected?.title,
      recaptcha_token : recaptchaToken,
      url: url_data
    }
    
    const generateToken = async () => {
      const token = await microSiteEncrypt();
      if (token && token !== "") {
        dispatch(submitLeadForm(token ,payload, props?.source, current_flow, onSuccess))
      }
    };
      
    generateToken();
  }

  const handleCountryChange = (value) => {
    if (value) {
      try {
        const phoneNumberObj = parsePhoneNumber(value);
        const countryCode = '+' + phoneNumberObj?.countryCallingCode;
        setCountryCode(countryCode);
      } catch (error) {
        console.error('Invalid phone number', error);
      }
    } else {
      setCountryCode('');
    }
  };
  
  const destroyAllError = () => {
    setSelected({});
    setNameError("");
    setEmailError("");
    setMobileError("");
    setPincodeError("");
    setAgeError("");
    setCheckFlag(false);
  }
  
  const onSuccess = () => {
    setSelected({});
    setName("");
    setEmail("");
    setMobile("");
    setPincode("");
    setAge("");
    setMessage("");
    setCheckFlag(false);
    refreshRecaptcha();
    if (props.formType === "best_selling") {
      props.handleDownload();
    }
    props.handleClose();
  }
  
  const refreshRecaptcha = () => {
    setRecaptchaKey(prev => prev + 1);
  };
  
  return (
    <>
        <Transition show={props.open}>
          <Dialog className="relative z-10" onClose={props.handleClose}>
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-[#e1dfe0] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                    <div className="px-8 max-sm:px-5 py-4">
                      <X
                        className="absolute right-2 top-2 z-50 text-[#222] cursor-pointer text-2xl"
                        onClick={() => {props.handleClose(); destroyAllError();}}
                      />
                      <div className="border-b pb-4">
                        <p className="text-[24px] font-[TATAAIARegularPrint] text-[#0073BB] font-semibold">That’s great!</p>
                        <p className="text-sm font-[TATAAIAPrint]">
                          Please share your information. Will get back to you.
                        </p>
                      </div>

                      <form action="#" method="POST" className="my-4 max-sm:mt-0">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-3 sm:grid-cols-3">
                          <div>
                            <label
                              htmlFor="name"
                              className="block text-xs font-medium font-[HelveticaNeueLTStd] text-gray-900"
                            >
                              Name <span className="text-[#D62049]">{(customize_fields?.top_contact?.name?.validation == "required" ? " *":"")}</span>
                            </label>
                            <div className="mt-1 relative">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className={`block w-full h-9 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${nameError != false ? "ring-red-300 focus:ring-red-300":"ring-gray-300 focus:ring-[#0073BB]"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                value={name}
                                onChange={(e) => {  
                                  const regex = /^[a-zA-Z ]*$/;
                                  if (regex.test(e.target.value)) {
                                    setName(e.target.value);
                                  }  
                                  if (e.target.value != "") {
                                    setNameError(false);
                                  }
                                }}
                              />
                              {nameError != false && <ExclamationCircleFill title="Enter a name" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="name"
                              className="block text-xs font-[HelveticaNeueLTStd] font-medium text-gray-900"
                            >
                              Mobile <span className="text-[#D62049]">{(customize_fields?.top_contact?.phone?.validation == "required" ? " *":"")}</span>
                            </label>
                          <div className="mt-1 relative country-customize">
                            <PhoneInput
                                flags={flags}
                                placeholder=""
                                value={mobile}
                                defaultCountry="IN"
                                limitMaxLength
                                countryCallingCodeEditable={false}
                                international={true}                            
                                onChange={(e) => {
                                  handleCountryChange(e);   
                                  if (e) {
                                    setMobile(e)
                                    if (isValidPhoneNumber(e)) {
                                        setMobileError(false); 
                                    } else {
                                        setMobileError(true); 
                                    }
                                  } else {
                                    setMobile("");
                                    setMobileError(false); 
                                  }
                                }}
                              />
                              {mobileError != false && <ExclamationCircleFill title="Enter a valid mobile number" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                            </div>
                          </div>
                          {props.formType == "join advisor" && <div>
                            <label
                              htmlFor="name"
                              className="block text-xs font-[HelveticaNeueLTStd] font-medium text-gray-900"
                            >
                              Pincode
                            </label>
                            <div className="mt-1 relative">
                              <input
                                type="text"
                                name="pincode"
                                id="pincode"
                                className={`block w-full h-9 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${pincodeError != false ? "ring-red-300 focus:ring-red-300":"ring-gray-300 focus:ring-[#0073BB]"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                value={pincode}
                                onChange={(e) => {
                                  const regex = /^\d{6}$/;                       
                                  if (regex.test(e.target.value) || e.target.value == "") {
                                    setPincodeError(false);
                                  } else {
                                    setPincodeError(true);
                                  }   
                                  if (e.target?.value?.length <= 6) {
                                    setPincode(e.target.value)
                                  }
                                }}
                            />
                            {pincodeError != false && <ExclamationCircleFill title="Enter a valid pincode" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                            </div>
                          </div>}
                          {props.formType != "join advisor" && <div>
                            <label
                              htmlFor="name"
                              className="block font-[HelveticaNeueLTStd] text-xs font-medium text-gray-900"
                            >
                              Email <span className="text-[#D62049]">{(customize_fields?.top_contact?.email?.validation == "required" ? " *":"")}</span>
                            </label>
                            <div className="mt-1 relative">
                              <input
                                type="email"
                                name="email"
                                id="email"
                                className={`block w-full h-9 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${emailError != false ? "ring-red-300 focus:ring-red-300":"ring-gray-300 focus:ring-[#0073BB]"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                value={email}
                                onChange={(e) => {
                                  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;                       
                                  if (regex.test(e.target.value) || e.target.value == "") {
                                    setEmailError(false);
                                  } else {
                                    setEmailError(true);
                                  }   
                                  setEmail(e.target.value)
                                }}
                              />
                             {emailError != false && <ExclamationCircleFill title="Enter a valid email" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                            </div>
                          </div>}
                          {props.formType == "join advisor" && <>
                            <div>
                              <label
                                htmlFor="name"
                                className="block font-[HelveticaNeueLTStd] text-xs font-medium text-gray-900"
                              >
                                Age
                              </label>
                              <div className="mt-1 relative">
                                <input
                                  type="number"
                                  name="age"
                                  id="age"
                                  className={`block w-full h-9 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${ageError != false ? "ring-red-300 focus:ring-red-300":"ring-gray-300 focus:ring-[#0073BB]"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                  value={age}
                                  onChange={(e) => {
                                    const regex = /^(?:1[01][0-9]|120|[1-9]?[0-9])$/;                       
                                    if (regex.test(e.target.value) || e.target.value == "") {
                                      setAgeError(false);
                                    } else {
                                      setAgeError(true);
                                    }
                                    if (e.target.value < 121) {
                                      setAge(e.target.value)
                                    }                                    
                                  }}
                                />
                                {ageError != false && <ExclamationCircleFill title="Enter a age between 1 - 120" className="text-xs absolute top-1/2 text-[#D62049] -translate-y-1/2 right-1.5"/>}
                              </div>
                            </div>
                            <div className="sm:col-span-2">
                              <Listbox value={selected} onChange={setSelected}>
                                    <Label className="block font-[HelveticaNeueLTStd] text-xs font-medium text-gray-900">
                                      Qualification
                                    </Label>
                                    <div className="relative mt-1">
                                      <ListboxButton className="relative w-full h-9 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 sm:text-sm sm:leading-6">
                                        <span className="">
                                          {selected.title}
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </ListboxButton>

                                        <ListboxOptions transition className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {qualification_list?.map((listData) => (
                                            <ListboxOption
                                              key={listData.id}
                                              className={({ focus }) =>
                                                classNames(
                                                  focus
                                                    ? "bg-gray-600 text-white"
                                                    : "",
                                                  !focus ? "text-gray-900" : "",
                                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                                )
                                              }
                                              value={listData}
                                            >
                                                  <span
                                                    className={classNames(
                                                      selected
                                                        ? "font-normal"
                                                        : "font-normal",
                                                      "block truncate"
                                                    )}
                                                  >
                                                    {listData.title}
                                                  </span>
                                            </ListboxOption>
                                          ))}
                                        </ListboxOptions>
                                    </div>
                              </Listbox>
                            </div>
                          </>}
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="message"
                              className="block font-[HelveticaNeueLTStd] text-xs font-medium text-gray-900"
                            >
                              Message
                            </label>
                            <div className="mt-1">
                              <textarea
                                name="message"
                                id="message"
                                rows={3}
                                className="block w-full resize-none rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                value={message}
                                onChange={(e)=>{setMessage(e.target.value)}}
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3 relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="terms"
                                aria-describedby="lead-message"
                                name="terms"
                                type="checkbox"
                                className="h-4 w-4 rounded border-[#a7aaaf] text-[#0073BB] focus:ring-[#0073BB]"
                                checked={checkFlag}
                                onChange={(e)=>{setCheckFlag(e.target.checked)}}
                              />
                            </div>
                            <div className="ml-3 w-full max-sm:w-full text-sm leading-6">
                              <span
                                id="lead-message"
                                className="text-[#444] font-[HelveticaNeueLTStd] text-[12px] not-italic font-normal leading-[160%]"
                              >
                                TATA AIA Life Insurance Co. Ltd will send you
                                updates on your policy, new products and services,
                                insurance solutions or related information.
                              </span>
                            </div>
                          </div>
                          <RecaptchaComponent 
                            key={recaptchaKey} 
                            onVerify={setRecaptchaToken} 
                          />
                          <button
                            type="button"
                            className={`flex  ${isLoading != false ? "w-20 h-[28px]":"w-fit"} h-[30px] ${ checkFlag !== false ? "bg-[#D62049]":"bg-[#dc5c7a]"} justify-center max-sm:m-auto items-center gap-2.5 shrink-0 border px-5 py-4 rounded-[30px]`}
                            onClick={() => {
                              console.log("recaptchaToken", recaptchaToken);
                              if (checkFlag != false) {
                                submitForm();
                              }
                            }}
                            disabled={checkFlag != true}
                          >
                            {isLoading == true ? <svg aria-hidden="true" className="w-4 h-4 text-[#D62049] animate-spin dark:text-gray-600 fill-[#fff]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            :
                            <span className="text-[#fff] font-[TATAAIARegularPrint] text-sm not-italic font-bold leading-5">
                              Submit
                            </span>}
                          </button> 
                        </div>
                      </form>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
    </>  
  );
}
