// RecaptchaComponent.js
import React, { useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Cookies from "js-cookie";

const RecaptchaComponent = ({ onVerify }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      try {
        const token = await executeRecaptcha('lead_form');
        // Store token in cookie for API verification
        Cookies.set("r_c", token);
        // Pass token to parent component
        onVerify(token);
      } catch (error) {
        console.error('reCAPTCHA error:', error);
        onVerify('');
      }
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, onVerify]);

  // V3 is invisible, so no need to render anything
  return null;
};

export default RecaptchaComponent;
